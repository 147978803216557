import React, { useContext, useEffect, useState } from 'react';


import { Doughnut } from 'react-chartjs-2';
import BoxContainer from '../../../components/boxcontainer';
import ModalFilters from '../../../components/modalfilters';
import moment from 'moment';
import InputDate from '../../../components/inputdate';
import { CustumerContext } from "../../../contexts/custumer"
import APIRondas from '../../../services/APIRondas';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
} from 'chart.js';



import {
  ContentFilter,
  Section,
  RowContainer
} from "./styles"
import { IRelacaoRondaResponse, IRelacaoRondasBody } from '../../../@interfaces';
import LabelEmptyData from '../../../components/labelemptydata';
import { IStorageFilter } from '../../../@interfaces';
import { TempStorage } from '../../../storage/temp';
import { useTheme } from 'styled-components';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
);




const DATE_FORMAT = "YYYY-MM-DD"


const RelacaoRondas: React.FC = () => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [dateIni, setDateIni] = useState(moment().subtract(1, "month").format(DATE_FORMAT))
  const [dateFim, setDateFim] = useState(moment().format(DATE_FORMAT))
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { id_current_custumer } = useContext(CustumerContext);

  const theme = useTheme();

  useEffect(() => {
    if (id_current_custumer && id_current_custumer > 0) {
      const tempStorage = new TempStorage();
      const storageFilter = tempStorage.getRelacaRondaFilter();

      if (storageFilter) {
        const { dataFinal, dataInicial } = storageFilter;
        setDateFim(dataFinal);
        setDateIni(dataInicial)
      }

      getData(storageFilter)
    }
  }, [id_current_custumer]);




  async function getData(objDate?: IStorageFilter | null) {
    try {
      setLoading(true)

      let ini = dateIni, fim = dateFim;

      if (objDate) {
        ini = objDate.dataInicial;
        fim = objDate.dataFinal;
      }

      const body: IRelacaoRondasBody = {
        cliente: id_current_custumer,
        dataFinal: moment(fim).format("YYYYMMDD"),
        dataInicial: moment(ini).format("YYYYMMDD")
      }

      const response: IRelacaoRondaResponse = await APIRondas.getRelacaoRondas(body);

      setLoading(false)

      if (!response.error) {
        const { concluidas, perdidas, interrompidas, offLine, concluidasOffline } = response;

        if (!concluidas && !perdidas && !interrompidas && !offLine) {
          return setData(null);
        }


        let _labels = [], _backgroundColor = [], _borderColor = [], _data = [];

        if (concluidas! > 0) {
          _labels.push("Conluídas");
          _backgroundColor.push(theme.colors.babyBlue);
          _borderColor.push(theme.colors.blue);
          _data.push(concluidas);
        }

        if (perdidas! > 0) {
          _labels.push("Perdidas");
          _backgroundColor.push(theme.colors.lightPink);
          _borderColor.push(theme.colors.pink);
          _data.push(perdidas);
        }

        if (interrompidas! > 0) {
          _labels.push("Interrompidas");
          _backgroundColor.push(theme.colors.greenyGray);
          _borderColor.push(theme.colors.pine);
          _data.push(interrompidas);
        }

        if (offLine! > 0) {
          _labels.push("Offline");
          _backgroundColor.push(theme.colors.creamYellow);
          _borderColor.push(theme.colors.royalYellow);
          _data.push(offLine)
        }

        if (concluidasOffline! > 0) {
          _labels.push("Concluída Offline");
          _backgroundColor.push(theme.colors.lightOrange);
          _borderColor.push(theme.colors.orange);
          _data.push(concluidasOffline)
        }

        const data = {
          labels: _labels,
          datasets: [
            {
              data: _data,
              backgroundColor: _backgroundColor,
              borderColor: _borderColor,
              borderWidth: 1,
            },
          ],
        }
        setData(data);
      }
    } catch (error) {
      setLoading(false)
      throw new Error("getRelacaoRondas: " + error);
    }
  }





  function showFilter() {
    setOpenFilter(true);
  }

  function handleDateIni(e: React.FormEvent<HTMLInputElement>) {
    setDateIni(moment(e.currentTarget.value).format(DATE_FORMAT))
  }

  function handleDateFim(e: React.FormEvent<HTMLInputElement>) {
    setDateFim(moment(e.currentTarget.value).format(DATE_FORMAT))
  }


  function cleanFilters() {
    setDateIni(moment().format(DATE_FORMAT))
    setDateFim(moment().format(DATE_FORMAT))
  }

  function applyFilter() {
    const tempStorage = new TempStorage();
    tempStorage.setRelacaRondaFilter(dateIni, dateFim);
    closeFilter();
    getData();
  }


  function closeFilter() {
    setOpenFilter(false)
  }



  return (
    <>
      {openFilter && (
        <ModalFilters handleClose={closeFilter} applyFilter={applyFilter} cleanFilter={cleanFilters}>
          <ContentFilter>
            <h2>Período</h2>
            <Section>
              <InputDate
                onChange={handleDateIni}
                value={dateIni}
                label="Início"
              />
              <InputDate
                onChange={handleDateFim}
                value={dateFim}
                label="Fim"
              />
            </Section>
          </ContentFilter>
        </ModalFilters>
      )}

      <BoxContainer title='Rondas' filterButton handleFilterButton={showFilter} loading={loading} gridArea={"rondas"}>
        {data && <Doughnut data={data} />}
        <RowContainer>
          <span className="dateShow">{moment(dateIni).format("DD/MM/YYYY")} - {moment(dateFim).format("DD/MM/YYYY")}</span>

          {!data && <LabelEmptyData style={{ marginTop: 20 }}>
            Nenhum dado a ser exibido
          </LabelEmptyData>}
        </RowContainer>
      </BoxContainer>
    </>
  )
}

export default RelacaoRondas;

