import React, { useContext, useState } from "react";

import Input from "../../components/input"
import Select from "../../components/select"
import Button from "../../components/button";
import APIAuth from "../../services/APIAuth";

import {
  Container,
  BallOverlay,
  Title,
  SubTitle,
  PowerdBy,
  LoginForm,
  AnimationContainer,
  Row,
  Link,
} from "./styles"

import SelectionLabel from '../../components/selectionlabel';
import Options from "../../components/select/options";
import Checkbox from "../../components/checkbox";
import ConfirmationModal from "../../components/confirmationmodal";
import Loader from "react-loader-spinner";
import ToastContext from "../../contexts/toast";
import AuthContext from "../../contexts/auth";
import { CustumerContext } from "../../contexts/custumer";

import APIUser from "../../services/APIUser";
import storage from "../../storage";
import PasswordChangeModal from "./../../components/passwordchangemodal";
import { CompanySelectOptions, Group } from "../../@types";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [company, setCompany] = useState<CompanySelectOptions>();
  const [password, setPassword] = useState<string>('');

  const [emailError, setEmailError] = useState<boolean>(false);

  const [companyList, setCompanyList] = useState<CompanySelectOptions[]>([]);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isEmailChecked, setIsEmailChecked] = useState<boolean>(false);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState<boolean>(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [isPasswordChangeModalOpen, setisPasswordChangeModalOpen] = useState<boolean>(false);
  const [animateBackwards, setAnimateBackwards] = useState<boolean>(false);

  const { showErrorToast, showSuccessToast } = useContext(ToastContext);
  const { signIn, setIsPassExpired, isPassExpired } = useContext(AuthContext);
  const { setListCustumer } = useContext(CustumerContext);
  const history = useHistory();

  const theme = useTheme();

  function handleClick() {
    if (!isEmailChecked) {
      checkEmail();
    } else if (!!company && !isReadyToSubmit) {
      setIsReadyToSubmit(true);
    } else if (isReadyToSubmit) {
      handleLogin();
    }
  }




  async function checkEmail() {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    try {
      setIsLoading(true);
      const isValid = email.match(regex);

      if (!isValid) {
        setEmailError(true);
        showErrorToast('E-mail inválido.')
        return;
      }

      const result = await APIAuth.validEmail(email);

      if (!result.err) {
        setCompanyList(formatOptions(result.groups));
        storage.setLicenseToken(result.token);
        setIsEmailChecked(true);
      } else {
        showErrorToast(result.message)
      }

    } catch {
      return;
    } finally {
      setIsLoading(false);
    }

  }




  function handleEmailChange(value: string) {
    setEmailError(false);
    setEmail(value);
  }




  function handleCompanyChange(value: string | number) {
    const company = companyList.find(item => item.value === value);
    setCompany(company);
    try {
      storage.setClientUrl(company?.url + '/sar/rest/api');
    } catch (e) {
      throw new Error("Erro ao trocar empresa: " + e)
    }
  }




  function handlePasswordChange(value: string) {
    setPassword(value);
  }




  function resetEmail() {
    setIsEmailChecked(false);
    setIsReadyToSubmit(false);
    setAnimateBackwards(true);
    setCompany({ label: '', value: 0, url: '', logo: '' });
    setPassword('');
  }




  function formatOptions(groups: Group[]) {
    const formatted = groups.map(item => {
      return {
        label: item.razaoSocial,
        value: item.group_code,
        url: item.url,
        logo: item.logo
      }
    });

    return formatted;
  }




  function openOptions() {
    setIsOptionsOpen(true);
  }




  function closeOptions() {
    setIsOptionsOpen(false);
  }




  function handlePasswordDisplay() {
    setIsPasswordVisible(!isPasswordVisible);
  }




  async function handleLogin() {
    try {
      setIsLoading(true);
      const result = await APIAuth.validLogin({ user: email, pass: password, code: company ? company.value : '' });
      if (!result.err) {

        storage.setAccessToken(result.token);
        storage.setGroupLogged(company!.label);
        storage.setLogoGroupLogged(company!.logo);

        const custumers = await APIUser.getClientList();

        if (!custumers.error && custumers.clientes) {
          setListCustumer(custumers.clientes);
          signIn(email);
        } else {
          setIsPassExpired(custumers.status === 100);
        }
        return;
      } else {
        showErrorToast(result.message);
      }
    } catch (e) {
      throw new Error("handleLogin: " + e)
    } finally {
      setIsLoading(false);
    }
  }




  function resolveButtonActivity() {
    if (!email || (isEmailChecked && !company) || (isReadyToSubmit && !password)) return true;
    return false
  }




  function formSubmit(e: React.FormEvent) {
    e.preventDefault();
    handleClick();
  }




  function showPasswordChangeModal() {
    setisPasswordChangeModalOpen(true);
  }




  function closePasswordChangeModal() {
    setisPasswordChangeModalOpen(false);
  }




  async function sendPasswordChangeEmail() {
    try {
      const result = await APIAuth.sendPasswordChangeEmail({ user: email, pass: '', code: company ? company.value : '' });

      if (!result.err) {
        showSuccessToast(`Código para troca de senha foi enviado para ${email}`);
        const params = {
          code: company?.value,
          email
        }
        const base64 = btoa(JSON.stringify(params));
        history.push(`trocadesenha/${base64}`)
      } else {
        showErrorToast(result.message);
      }
    } catch {
      return;
    }
  }




  async function handleChangeSuccess(token: string) {
    setIsPassExpired(false);

    storage.setAccessToken(token);

    const custumers = await APIUser.getClientList();

    if (!custumers.error && custumers.clientes) {
      setListCustumer(custumers.clientes);
      signIn(email);
    } else {
      if (custumers.status && custumers.status > 0) {
        showErrorToast("Não foi possível carregar clientes: " + custumers.message);
      }
    }
  }



  return (
    <Container>

      {isPassExpired && <PasswordChangeModal
        warning="Sua senha está expirada. Para usar a aplicação, cria uma nova senha."
        handleSuccess={handleChangeSuccess}
      />}

      {isPasswordChangeModalOpen &&
        <ConfirmationModal
          message="Será enviado em seu e-mail um código para que consiga efetuar a troca da senha"
          closeModal={closePasswordChangeModal}
          onActionClick={sendPasswordChangeEmail}
        />
      }
      <BallOverlay />
      <Title>Portal do Cliente</Title>
      <PowerdBy>Desenvolvido e mantido por Grupo HK</PowerdBy>


      <LoginForm onSubmit={formSubmit} >

        {!isEmailChecked &&
          <AnimationContainer direction={animateBackwards ? 'right' : ''}>
            <Input placeholder={"Email"} isEmail GAPVertical={20} value={email} onChange={event => handleEmailChange(event.target.value)} colorOfBorder={emailError ? theme.colors.red : ''} />
          </AnimationContainer>
        }

        {isEmailChecked &&
          <AnimationContainer direction={'left'}>
            <SelectionLabel label={email} onClick={resetEmail} style={{ marginTop: 20, marginBottom: 20 }} />
            <Select GAPVertical={20} label={company?.label || "Selecione seu prestador"} onOpenOptions={openOptions} />
          </AnimationContainer>
        }

        {isOptionsOpen && <Options selectOption={handleCompanyChange} close={closeOptions} itens={companyList} />}

        {isReadyToSubmit &&
          <AnimationContainer direction={'left'}>
            <Input placeholder={"password"} type={isPasswordVisible ? "text" : "password"} isPassword value={password} onChange={event => handlePasswordChange(event.target.value)} />
            <Row>
              <div>
                <Checkbox label={'Mostrar senha'} gapVertical={20} onCheck={handlePasswordDisplay} />
              </div>

              <Link onClick={showPasswordChangeModal}>Esqueci minha senha</Link>
            </Row>
          </AnimationContainer >
        }

        <Button type={'button'} onClick={handleClick} disabled={resolveButtonActivity()} loading={isLoading}>
          {isReadyToSubmit ? "Acessar" : "Próximo"}
        </Button>
      </LoginForm >
    </Container >
  )
}


export default Login;