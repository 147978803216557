import styled from "styled-components"

export const Container = styled.div`
  flex: 1;
  .dates{
    font-size: .75rem;
    margin-bottom: 20px;
    display:block;
  }
`

export const ContentFilters = styled.div`
  display: flex;
  width: 100%;
  flex-direction:column;
  padding: 20px;
  .filterTitle{
    margin-bottom: 20px;
  }
`

export const RowSection = styled.div`
  display: flex;
`