import styled from "styled-components";
import TitleComponent from "../../components/title"

interface ISection {
  marginTop?: number
}

interface Ibutton {
  changePass?: boolean;
}




export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh + 20px);
`

export const Section = styled.section<ISection>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ marginTop }) => {
    if (marginTop) {
      return `margin-top:${marginTop}px`
    }
  }}
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 7.5fr 2.5fr;
`


export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  @media(max-width: 700px){
    justify-content: space-between;
  }
`

export const Title = styled(TitleComponent)`
  font-size: ${props => props.theme.titleSize};
  font-weight: normal;
  margin-bottom: 20px;
`

export const TableContainer = styled.div`
  width: 100%;
  @media(max-width: 700px){
    display: none;    
  }
`


export const UserList = styled.ul`
  display: none;
  @media(max-width: 700px){
    display: flex;
    flex-direction: column;
  }
`

export const NewUserButton = styled.button<Ibutton>`
  background: ${props => props.theme.colors.primary};
  border: none;
  padding: 10px 20px;
  color: ${props => props.theme.colors.white};
  border-radius: 4px;
  align-self: flex-start;
  margin-bottom: 15px;
  margin-right: 10px;
  display: flex;
  gap: 10px;
  align-items: center;

  @media(max-width: 700px){
  margin-right: 0px;
  }

  &:hover{
    cursor: pointer;
  }

  ${({ changePass, theme }) => {
    if (changePass) {
      return `background:${theme.colors.darkSilver}`
    }
  }}

`