import styled from "styled-components";

import { IButtonStyleProps } from "../../@interfaces";

export const ButtonComponent = styled.button<IButtonStyleProps>`
  position: relative;
  width: 100%;
  padding: 6px 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  background: ${props => props.theme.colors.primary};
  font-size: .875rem;
  transition: all .1s;
  display: flex;
  justify-content: center;
  
  ${({ noHover }) => {
    if (!noHover) return `
     &:hover{
        box-shadow: inset 0 0 100px 100px rgb(29, 132, 196);
    }`
  }}

  
  &:disabled{
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.5);
  }


  ${({ width }) => {
    if (width) {
      return `width: ${width}px`;
    }
  }}

  ${({ height }) => {
    if (height) {
      return `width: ${height}px`;
    }
  }}

  ${({ colorButton }) => {
    if (colorButton) {
      return `background: ${colorButton}`;
    }
  }}
  
  ${({ colorLabel }) => {
    if (colorLabel) {
      return `color: ${colorLabel}`;
    }
  }}
`