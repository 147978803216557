import styled from "styled-components"

export const Container = styled.div`
  width: 100%;  
  display: grid;   
  grid-template-columns: 360px 1fr;
  grid-template-areas: 
  'rondas ocorrencias'
  'programacoes eficiencia';
  gap: 10px;

  @media (max-width: 1024px){
    grid-template-columns: 100%;
    grid-template-areas: 
    'rondas'
    'ocorrencias'
    'programacoes'
    'eficiencia';
  }
`


export const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;  
  width: 100%;
  gap:10px;
  margin-bottom: 10px;
  @media (max-width: 1024px){
    grid-template-columns: 100%;
  }
`

export const ListsContainer = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  width: 100%;
  gap:10px;  
  overflow: hidden;

  @media (max-width: 1024px){
    grid-template-columns: 100%;
  }
`

