import { AiOutlineUser, AiOutlineCalendar, AiOutlineDollarCircle, AiOutlineFolderOpen } from 'react-icons/ai';
import { BsQuestion, BsPeople } from 'react-icons/bs';
import { FiMapPin } from 'react-icons/fi';
import { ImFileText2 } from 'react-icons/im';
import { BsCurrencyDollar } from "react-icons/bs"
import storage from '../storage';
import React, { KeyboardEventHandler } from "react";
import { round, split } from 'lodash';
import { SelectOptions } from '../@types';
import { FaCalendarAlt, FaRegCalendarAlt } from 'react-icons/fa';

const aux = {
  getHeaders: function () {
    // const token = storage.getAccessToken();
    const userJson = {
      "login": storage.getAccessToken(),
      "licenca": storage.getLicenseToken()
    }
    const formattedJson = JSON.stringify(userJson).trim();

    const token = btoa(formattedJson);
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
    return headers;
  },

  validEmailFormat: function (email: string | undefined) {
    const isValid = email?.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return !!isValid;
  },

  resolveMenuIcon: function (id: number) {
    switch (id) {
      case 0:
        return ImFileText2;
      case 1:
        return FiMapPin;
      case 2:
        return AiOutlineUser;
      case 3:
        return AiOutlineDollarCircle;
      case 4:
        return AiOutlineCalendar;
      case 5:
        return BsPeople
      case 6:
        return AiOutlineFolderOpen
      default:
        return BsQuestion;
    }
  },

  onlyInputNumber: function (e: any) {
    const regex = /[0-9]|\./
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
  },

  parseToDDMMYYYY: function (date: string) {
    const splitted = date.split('-');
    const formatted = `${splitted[2]}/${splitted[1]}/${splitted[0]}`;
    return formatted;
  },

  parseToYYYYMMDD: function (date: string) {
    const splitted = date.split("/");
    const formatted = `${splitted[2]}/${splitted[1]}/${splitted[0]}`;
    return formatted;
  },

  getCurrentDate: function () {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0');
    const currentDate = today.getDate().toString().padStart(2, '0');

    return `${currentYear}-${currentMonth}-${currentDate}`;
  },

  getAMonthAgoDate: function () {
    let today = new Date();
    let currentMonth = today.getMonth();
    today.setMonth(today.getMonth() - 1);
    if (currentMonth === today.getMonth()) today.setDate(0);

    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const date = today.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${date}`;
  },

  maskReal: function (rawValue: string) {
    const value = rawValue.replace(',', '.');
    const rounded = parseFloat(value).toFixed(2);

    const splittedValue = rounded.split('.');
    const firstHalf = splittedValue[0];
    let formattedValue = '';
    const startIndex = firstHalf.length - 1;

    for (let i = startIndex; i >= 0; i--) {
      if ((startIndex - i) % 3 === 0 && (startIndex - i) !== 0) {
        formattedValue = `${firstHalf[i]}.${formattedValue}`
      } else {
        formattedValue = `${firstHalf[i]}${formattedValue}`;
      }
    }

    const cents = splittedValue[1] ? splittedValue[1] : "00";
    return `R$ ${formattedValue},${cents}`
  },


  sortOptionsByLabel: function (list: SelectOptions[]) {
    const sortedList = list.sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0);
    return sortedList;
  },


  formatDateToString: function (date: string) {
    return date.replaceAll("-", "");
  },

  downloadFile: function (base64: string, name: string) {
    const linkSource = base64;

    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
  },


  getToday: function () {
    const date = new Date();
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}`
  },







  formatRawDateToDate: function (rawDate: string) {
    const date = new Date(rawDate);

    const formattedDate = `${(date.getDate() + 1).toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;

    return formattedDate;
  },




  formartForOptionComponent: function (data: any[], columnValue: string, columnDesc: string) {
    const newStr = data.map(i => {
      return {
        value: i[columnValue],
        label: i[columnDesc]
      }
    })

    return newStr;
  },



  checarErros: (camposObrigatorios: string[], dados: Object) => {
    let erros: string[] = []
    for(const campo of camposObrigatorios){
      if(!dados[campo as keyof Object]) erros = [...erros, campo];
    }

    return erros;
  }

}

export default aux;