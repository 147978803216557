import styled, { keyframes } from "styled-components";

interface IProps {
  isLabelVisible: boolean;
  isActive: boolean;
}

export const Container = styled.a<IProps>`
  width: 100%;
  overflow:hidden;
  display: grid;
  grid-template-columns:${props => props.isLabelVisible ? '2fr 7fr 1fr' : '1fr'};
  ${props => !props.isLabelVisible && 'justify-items: center'};
  align-items: center;
  color: #fff;
  padding: 20px 0 20px ${props => props.isLabelVisible && '10px'};;
  transition: all .2s;
  :hover{
    cursor: pointer;
    background: rgba(255,255, 255, .1)
  }


  ${({ isActive, theme }) => {
    if (isActive) {
      return `background: rgba(255,255, 255, .1); 
        color:${theme.colors.white};
       
        >p{
          font-weight: 500
        }
      `
    } else {
      return `background: none;color:#fff`
    }
  }}
`

const animaLabel = keyframes`
  from{
    opacity: 0;
  }to{
    opacity: 1;
  }
`

export const Label = styled.p`
  font-size: .875rem;
  font-weight: 300;
  opacity: 0;
  animation: ${animaLabel} 1.2s linear forwards; 
`



