import styled from "styled-components"

interface IProps {
  gridArea?: string;
}

export const Content = styled.div`
  
`

export const Container = styled.div<IProps>`
  padding: 0px 10px 10px;
 
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  max-width: 100%;
 
 

  ${({ gridArea }) => {
    if (gridArea) {
      return `grid-area: ${gridArea}`
    }
  }}

`

export const Header = styled.div`
  width: 100%;
  padding: 9px 0 9px;
  border-bottom: 1px solid rgba(0,0,0,.1);
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  >a{
    cursor: pointer;
    margin-left: 10px;
  }
`