import React, { useContext, useEffect, useState } from 'react';
import { BsChevronCompactDown } from "react-icons/bs"
import { Container, ContainerInput, FilterContainer, DropDown, InputDate, Row, ButtonSearch } from './styles';
import Listafuncionarios from './listafuncionarios';
import Utils from "../../utils/auxiliar"
import Options from '../../components/select/options';
import { CustumerContext } from '../../contexts/custumer';
import APIPonto from '../../services/APIPonto';
import APIArquivos from '../../services/APIArquivos';
import ToastContext from "../../contexts/toast"
import Loading from '../../components/loading';
import Input from '../../components/input';
import ApiGeral from '../../services/ApiGeral';
import moment from 'moment';
import EmployeeDetail from './employeeDetail';
import { cloneDeep, orderBy } from 'lodash';
import { useTheme } from 'styled-components';




interface IFuncionario {
  funcionario: number,
  nome: string,
  empresa: string,
  dtAdmissao: string,
  avatar: null | string,
  arquivoFoto?: number
}

const Funcinarios: React.FC = () => {
  const [dateInit, setDateInit] = useState<any>('')
  const [dateFin, setDateFin] = useState<any>('')
  const [locais, setLocais] = useState<any[]>([])
  const [empresas, setEmpresas] = useState<any[]>([])
  const [localSelected, setLocalSelected] = useState<any>(null)
  const [companySelected, setCompanySelected] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false);
  const [showOptions, setShowOption] = useState<{ show: boolean, data?: any[], handle?: (value: string | number) => void }>({ show: false })
  const { id_current_custumer } = useContext(CustumerContext);
  const { showErrorToast } = useContext(ToastContext);
  const [funcionarios, setFuncionarios] = useState<IFuncionario[]>([]);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [showUser, setShowUser] = useState<{ show: boolean, id?: number | string, avatar?: string }>({ show: false });

  const [searchValue, setSearchValue] = useState<string>();

  const theme = useTheme();


  useEffect(() => {
    initComponent()
  }, [])



  useEffect(() => {
    // Se mudar o cliente, esse cara será disparado
  }, [id_current_custumer])



  async function initComponent() {
    try {
      setLoading(true);
      getDefaultDate();
      const places = await APIPonto.getPlacesList({ cliente: id_current_custumer, SomentePontoEletronico: false });
      if (!places.error) {
        setLocais(Utils.formartForOptionComponent(places.local!, 'local', 'descricao'))
      } else {
        setLoading(false);
        showErrorToast(`Não foi possível carregar seus locais: ${places.mensagem}`);
        return;
      }


      const companies = await APIPonto.getCompanies();
      if (!companies.error) {
        setEmpresas(Utils.formartForOptionComponent(companies.empresas!, 'empresa', 'nome'))
      } else {
        setLoading(false);
        showErrorToast(`Não foi possível carregar as empresas: ${places.mensagem}`);
        return;
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      showErrorToast(`Não foi possível carregar filtros: ${error}`)
    }
  }






  function getDefaultDate() {
    const d = new Date();
    const dia = String(d.getDate()).padStart(2, '0');
    const mes = String(d.getMonth() + 1).padStart(2, '0');
    const ano = d.getFullYear();
    const _str = `${ano}-${mes}-${dia}`;
    setDateInit(_str);
    setDateFin(_str);
  }



  function handleDateIni(e: React.ChangeEvent<HTMLInputElement>) {
    setDateInit(e.target.value)
  }




  function handleDateFin(e: React.ChangeEvent<HTMLInputElement>) {
    setDateFin(e.target.value)
  }





  function openOptions(drop: 'local' | 'empresa') {
    if (drop === 'local') {
      setShowOption({
        show: true,
        data: locais,
        handle: (value: string | number) => setLocalSelected(locais.find(i => i.value === value).label)
      })
    } else {
      setShowOption({
        show: true,
        data: empresas,
        handle: (value: string | number) => setCompanySelected(empresas.find(i => i.value === value).label)
      })
    }
  }





  function closeOptions() {
    setShowOption({ show: false })
  }




  async function search() {
    try {
      setLoadingButton(true);

      if (new Date(dateInit) > new Date(dateFin)) {
        setLoadingButton(false)
        return showErrorToast('Data inicial superior a data final')
      }

      const _local = locais.find(i => i.label === localSelected).value;
      const _empresa = empresas.find(i => i.label === companySelected).value;

      const body: any = {
        cliente: id_current_custumer,
        local: _local,
        empresa: _empresa,
        dataInicio: moment(dateInit).format('YYYYMMDD'),
        dataFinal: moment(dateFin).format('YYYYMMDD'),
      };

      const response = await ApiGeral.getFuncionariosList(body);

      if (!response.error) {
        const sortedList: any[] = orderBy(response.funcionario, ["nome"], ["asc"]);

        setFuncionarios(sortedList?.map(i => {
          return {
            funcionario: i.funcionario,
            nome: i.nome,
            empresa: i.empresa,
            dtAdmissao: i.dataAdmissao,
            avatar: null,
          }
        }));



        getFotoFuncionarios(sortedList);


      } else {
        setFuncionarios([]);
        showErrorToast(response.mensagem!)
      }

      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      showErrorToast('Não foi possível realizar pesquisa: ' + error);
    }
  }





  async function getFotoFuncionarios(funcionarios: IFuncionario[]) {
    try {
      const files: any = funcionarios.map(func => func.arquivoFoto);
      const pictures = await APIArquivos.getArquivo({ Arquivos: files });

      if (!pictures.error) {
        const _newListFunc = cloneDeep(funcionarios);

        for (const pic of pictures.listaArquivo!) {
          if (pic.conteudo) {
            const index = _newListFunc.findIndex(f => f.arquivoFoto === pic.arquivo);
            _newListFunc[index].avatar = pic.conteudo;
          }
        }

        setFuncionarios(_newListFunc);
      }
    } catch (error) {
      throw new Error('getFotoFuncionarios: ' + error)
    }
  }



  function openUser(id: number | string) {
    const index = funcionarios.findIndex(f => f.funcionario === id);
    const func = funcionarios[index];

    setShowUser({ show: true, id, avatar: func.avatar ? func.avatar : '' })
  }


  function closeUser() {
    setShowUser({ show: false })
  }




  function filterBySearch(value: string) {
    setSearchValue(value);
  }



  const filteredList = !!searchValue ? funcionarios!.filter(item => item.nome.toUpperCase().includes(searchValue!.toUpperCase())) : [];

  if (showUser.show) {
    return <EmployeeDetail
      employeeId={Number(showUser.id)}
      finalDate={dateFin}
      startDate={dateInit}
      close={closeUser}
      local={locais.find(i => i.label === localSelected).value}
      employeePhoto={showUser.avatar}
    />
  }




  return (
    <>
      {
        showOptions.show && <Options
          itens={showOptions.data}
          close={closeOptions}
          selectOption={showOptions.handle!}
        />
      }
      {loading && <Loading />}
      {!loading && <Container>
        <FilterContainer>
          <Row>
            <ContainerInput>
              <label>Local</label>
              <DropDown onClick={() => openOptions('local')}>
                <span>{localSelected ? localSelected : 'Escolha o local'} </span>
                <BsChevronCompactDown />
              </DropDown>
            </ContainerInput>


            <ContainerInput>
              <label>Empresa</label>
              <DropDown onClick={() => openOptions('empresa')}>
                <span>{companySelected ? companySelected : 'Escolha a empresa'} </span>
                <BsChevronCompactDown />
              </DropDown>
            </ContainerInput>

            <ContainerInput>
              <label>Período</label>
              <InputDate type={'date'} value={dateInit} onChange={handleDateIni} />
              -
              <InputDate type={'date'} value={dateFin} onChange={handleDateFin} />
            </ContainerInput>

            <ContainerInput>
              {localSelected && companySelected && <ButtonSearch onClick={search} disabled={loading} loading={loadingButton}>
                {loadingButton ? <Loading width={13} height={13} noPadding /> : 'Buscar'}
              </ButtonSearch>}
            </ContainerInput>


            {funcionarios.length > 0 && <Input
              placeholder={"Buscar..."}
              isSearch colorOfBorder={"#cecece"}
              value={searchValue}
              onChange={(e) => filterBySearch(e.target.value)}
              containerStyle={{ marginLeft: 100 }}
            />}

          </Row>
        </FilterContainer>



        <Listafuncionarios data={filteredList.length > 0 ? filteredList : funcionarios!} openUser={openUser} />
      </Container>}
    </>
  )
}

export default Funcinarios;