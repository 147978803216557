import { useContext, useEffect, useState } from "react"
import { ILocal, IRegistros, IVaga } from "../../@interfaces"
import APIPonto from "../../services/APIPonto"
import { Container, Dropdown, EntryCell, EntryItem, FetchButton, Input, SearchContainer, SectionContainer, SectionHeader, SettingsContainer } from "./styles"
import { BsChevronCompactDown } from "react-icons/bs"
import { AiOutlineSearch } from "react-icons/ai"
import Loading from '../../components/loading'
import { SelectOptions } from "../../@types"
import ToastContext from "../../contexts/toast"
import aux from "../../utils/auxiliar"
import { CustumerContext } from "../../contexts/custumer"
import Options from "../../components/select/options"
import EmptyListMessage from "../../components/mensagemlistavazia"


const Ponto: React.FC = () => {
	const [placeList, setPlaceList] = useState<SelectOptions[]>();
	const [data, setData] = useState<IVaga[]>();
	const [selectedPlace, setSelectedPlace] = useState<number|string>();
	const [selectedDate, setSelectedDate] = useState<string>();
	const [isPlaceOptionsOpen, setPlaceOptionsOpen] = useState<boolean>(false);

	const [search, setSearch] = useState<string>();
	const [isListLoading, setIsListLoading] = useState<boolean>(false);
	const [isPlacesLoading, setIsPlacesLoading] = useState<boolean>(false);

	const { showErrorToast } = useContext(ToastContext);
	const {id_current_custumer} = useContext(CustumerContext);


	useEffect(() => {
		loadPlaces();
    setData(undefined);
    setSelectedPlace("");
    setSelectedDate("");
	}, [id_current_custumer]);



	async function loadPlaces(){
		try{
			setIsPlacesLoading(true);
			const body = {
				cliente: id_current_custumer,
				SomentePontoEletronico: true
			}

			const response = await APIPonto.getPlacesList(body);



			if(!response.error){
				const formattedOptions = formatOptions(response.local!);
				setPlaceList(formattedOptions);
			}else{
				showErrorToast(response.message);
				setPlaceList([]);
			}
		}catch{
			showErrorToast("Algo deu errado.");
		}finally{
			setIsPlacesLoading(false);
		}
	}
	



	async function loadData(){
		try{
			setIsListLoading(true);
			const body = {
				local: selectedPlace!,
				dataPonto: aux.formatDateToString(selectedDate!)
			}

			const response = await APIPonto.getPontoList(body);
			if(!response.error){
				setData(response.vaga)
			}else{
				setData([])
				showErrorToast(response.message)
			}

		}catch(e){
			console.log(e)
			showErrorToast('Algo deu errado');
		}finally{
			setIsListLoading(false);
		}
	}

	


	function formatOptions(list: ILocal[]){
		const newArray = list.map(item => {
			return {
				value: item.local,
				label: item.descricao
			}
		})

		return newArray;
	}




	function handleDateChange(value: string){
		setSelectedDate(value);
	}




	function openPlaceOptions(){
		setPlaceOptionsOpen(true);
	}




	function closePlaceOptions(){
		setPlaceOptionsOpen(false);
	}




	function handleSelectPlace(value: number | string){
		setSelectedPlace(value);
		closePlaceOptions();
	}



	function handleSearch(value: string){
		setSearch(value.toUpperCase());
	}



	let entriesToShow: IRegistros[][] = [];

	if(data){
		for(let i of data!){
			const aux = !!search ? 
			i.registroMarcacao.filter(item => item.nome.toUpperCase().includes(search) || String(item.rE).toUpperCase().includes(search) )
			: i.registroMarcacao;
			
			entriesToShow.push(aux);
		}
	}

  const showNoPlaceMessage = placeList?.length === 0 && !isPlacesLoading;
  const showNoDataMessage = data?.length === 0 && !isListLoading && !showNoPlaceMessage;

	return(
		<Container>

			{isPlaceOptionsOpen && 
        <Options
          close={closePlaceOptions}
          selectOption={handleSelectPlace}
          itens={placeList}
          sortAlphabetically 
        />

			}

			{placeList && placeList?.length > 0 && !isPlacesLoading && 
			<SettingsContainer>
				<div>
					<label>
						Local
					</label>
					<Dropdown onClick={openPlaceOptions}>
						<span>{placeList!.find(item => item.value === selectedPlace)?.label	|| 'selecione o local'}</span>
						<BsChevronCompactDown />
					</Dropdown>
				</div>

				<div>
					<label htmlFor="date_input">
						Data
					</label>

					<Input type={'date'} value={selectedDate} onChange={e => handleDateChange(e.target.value)} max={aux.getToday()} id="date_input"/>
				</div>

				{selectedDate && selectedPlace &&<div>
					<FetchButton onClick={loadData}>
						Buscar
					</FetchButton>
				</div>}

				{data && data.length> 0 && <SearchContainer>
					<Input placeholder="Pesquise por RE ou nome" width={300} onChange={e => handleSearch(e.target.value)}/>
					<AiOutlineSearch style={{ position: 'absolute', right: 10 }}/>
				</SearchContainer>}

			</SettingsContainer>}

			{isPlacesLoading && <Loading />}
			{showNoPlaceMessage && <EmptyListMessage texto="Nenhum local disponível" />}

			{data && data.map((item, index) => 
				<SectionContainer>
					{entriesToShow[index]!.length > 0 &&
						<SectionHeader>
							<h3>{'Turno: '}</h3> 
							<span>{item.turno}</span> 
							<span> | </span> 

							<h3>{'Cargo: '}</h3> 
							<span>{item.cargo}</span>
							<span> | </span> 

							<h3 style={{ color: '#4C7AFB'}}> {'Entrada: '}</h3> 
							<span>{item.entrada}</span>
							<span> | </span> 

							<h3 style={{ color: '#47CC64'}}>{'S. Ref: '}</h3> 
							<span>{item.saidaRefeicao}</span>
							<span> | </span> 

							<h3 style={{ color: '#e07a5f'}}>{'R. Ref: '}</h3> 
							<span>{item.retornoRefeicao}</span>

							<span> | </span> 
							<h3 style={{ color: '#ffa62b'}}>{'Saida: '}</h3> 
							<span>{item.saida}</span>  
						</SectionHeader>
						}

					<ul>

						{entriesToShow[index]!.map(registro => {
							return(
								<EntryItem>
									<EntryCell>
										<h3>
											RE
										</h3>
										<span>
											{registro.rE}
										</span>
									</EntryCell>

									<EntryCell>
										<h3>
											Nome
										</h3>
										<span>
											{registro.nome}
										</span>
									</EntryCell>

									<EntryCell color="#4C7AFB" alignRight>
										<h3>
											Entrada
										</h3>
										<span>
											{registro.entrada}
										</span>
									</EntryCell>

									<EntryCell color="#47CC64" alignRight>
										<h3>
											S. Ref
										</h3>
										<span>
											{registro.saidaRefeicao}
										</span>
									</EntryCell>

									<EntryCell color="#e07a5f" alignRight>
										<h3>
											R. Ref
										</h3>
										<span>
											{registro.retornoRefeicao}
										</span>
									</EntryCell>

									<EntryCell color="#ffa62b" alignRight>
										<h3>
											Saída
										</h3>
										<span>
											{registro.saida}
										</span>
									</EntryCell>
								</EntryItem>
							)
						}
						)}
					</ul>
				</SectionContainer>
			)}

			{isListLoading && <Loading />}

			{showNoDataMessage && <EmptyListMessage texto="Nenhum dado disponível com esses parâmetros" />}
		</Container>
	)
}

export default Ponto;