import React, { useContext, useEffect, useState } from "react";

import MenuContext from "../../contexts/menu";
import AuthContext from "../../contexts/auth";
import {
  Container,
  CompanyContainer,
  LogoContainer,
  CompanyName,
  MenuList,
  Version
} from "./styles"


import MenuItem from "../menuitem";
import aux from "../../utils/auxiliar";
import { useLocation } from "react-router-dom";
import storage from "../../storage"
import { name, version } from "../../../package.json"
const menuItems = [
  // { id: 0, label: 'Relatórios', link: 'relatorios' },
  { id: 1, label: 'Rondas', link: 'rondas' },
  { id: 2, label: 'Usuarios', link: 'usuarios' },
  { id: 3, label: 'Faturas e Boletos', link: 'financeiro' },
  { id: 4, label: 'Ponto', link: 'ponto' },
  { id: 5, label: 'Funcionários', link: 'funcionarios' },
  { id: 6, label: 'Documentos', link: 'documentos' }
]

const Aside: React.FC = ({ children }) => {
  const { isOpen, currentPage, navigateTo } = useContext(MenuContext);
  const { loggedEmail } = useContext(AuthContext)
  const [group, setGroup] = useState<string>("");
  const [logo, setLogo] = useState<string | null>(null);
  const location = useLocation();

  // useEffect(() => {
  //   if (!currentPage) {
  //     navigateTo(location.pathname.replace("/", ""));
  //   }
  // }, [currentPage])

  useEffect(() => {
    const group = storage.getGroupLogged();
    if (group)
      setGroup(group)
    const _logo = storage.getLogoGroupLogged();

    if (_logo && _logo !== "null") {
      setLogo(_logo)
    }
  }, [])

  return (
    <Container isOpen={isOpen}>
      {isOpen && <CompanyContainer>
        {logo && <LogoContainer>
          <img src={logo} alt="logo" />
        </LogoContainer>}
        <CompanyName isOpen={isOpen}>{group}</CompanyName>
        <span>{loggedEmail}</span>
        <span>Portal do Cliente</span>
      </CompanyContainer>}
      <MenuList>
        {menuItems.map((item, index) => (
          <li key={index}>
            <MenuItem
              label={item.label}
              link={item.link}
              Icon={aux.resolveMenuIcon(item.id)}
              showLabel={isOpen}
              isActive={currentPage === item.link}
            />
          </li>
        ))}
      </MenuList>
      <Version>v{version}</Version>
    </Container>
  )
}

export default Aside;