import React, { createContext, useEffect, useState } from "react"
import storage from "../storage";

interface IContextProps {
  isLogged: boolean;
  loggedEmail: string | null;
  signIn: (email: string) => void;
  signOut: () => void;
  setIsPassExpired: (param: boolean) => void;
  isPassExpired?: boolean;
}


const AuthContext = createContext<IContextProps>({} as IContextProps);

export const AuthContextProvider: React.FC = ({ children }) => {
  const [isLogged, setIsLogged] = useState<boolean>(!!storage.getLoggedUser());
  const [loggedEmail, setLoggedEmail] = useState<string | null>(storage.getLoggedUser());
  const [isPassExpired, setIsPassExpired] = useState<boolean>(false);

  useEffect(() => {
    const logged = storage.getLoggedUser();
    if (logged) {
      setIsLogged(true);
    }
  }, [])


  function signIn(email: string) {
    setIsPassExpired(false);
    storage.setLoggedUser(email);
    setLoggedEmail(email);
    setIsLogged(true);
  }

  function signOut() {
    storage.clearAll();
    setIsLogged(false);
    if (isPassExpired) {
      setIsPassExpired(true);
    }
  }


  return (
    <AuthContext.Provider
      value={{
        isLogged,
        loggedEmail,
        signIn,
        signOut,
        isPassExpired,
        setIsPassExpired
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;