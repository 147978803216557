import styled, { css } from "styled-components";

export const Container = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	flex-direction: column;
`

export const SectionContainer = styled.section`
	position: relative;
	width: 100%;
`

export const SectionHeader = styled.div`
	background: #eee;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 7px;
	padding: 10px 0;
	position: sticky;
	top: -17px;
	width: 100%;
	height: 40px;

	h3 {
		color: rgba(0,0,0,.5);
		display: inline;
	}

	span {
		color: rgba(0,0,0,.4)
	}



`

export const EntryItem = styled.li`
	display: grid;
	grid-template-columns: 1fr 4fr repeat(4, 1fr);
	padding: 10px 0;
	justify-items: start;
`


interface ICellProps {
	color?: string
	alignRight?: boolean
}

export const EntryCell = styled.div<ICellProps>`

	display: flex;
	flex-direction: column;
	gap: 10px;
	${props => props.alignRight &&
	`
		justify-self: end;
		align-items: flex-end;
	`
	}

	span {
		color: rgba(0,0,0,.4);
    font-size: 16px;
	}
	
	h3 {
		color: ${props => props.color  || 'rgba(0,0,0,.5)'};
	}
	
`



export const SettingsContainer = styled.div`
	display: flex;
	width: 100%;
	margin: 20px 0;
	gap: 50px;

	> div {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	label {
		color: rgba(0,0,0,.5);
		font-weight: bold;
	}
`


const inputStyle = css`
	border: none;
	border-radius: 8px;
	background: #fff;
	padding: 7px 15px;
	width: 200px;
	display: flex;
	justify-content: space-between;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	outline: none;
	color: rgba(0,0,0,.5);
`


export const Dropdown = styled.button`
	${inputStyle}

	:hover{
		cursor: pointer;
	}

	span{
		color: rgba(0,0,0,.5);
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
	}
`


interface IInputProps {
	width?: number 
}

export const Input = styled.input<IInputProps>`
	${inputStyle}
	width: ${props => props.width ? props.width : 200}px
`

export const SearchContainer = styled.div`
	position: relative;
	margin-left: auto;
`


export const FetchButton = styled.button`
	border: none;
	background: ${props => props.theme.colors.primary};
	color: #fff;
	padding: 7px 15px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	border-radius: 4px;
	:hover {
		cursor: pointer;
	}
`


export const EmptyListMessage = styled.span`
	color: rgba(0,0,0,.5);
	letter-spacing: 1px;
`