import styled, { css, keyframes } from "styled-components";


const slideLeftKeyframes = keyframes`
  from{
    transform: translateX(500px);
  }to{
    transform: translateX(0);
  }
`

const slideRightKeyframes = keyframes`
  from{
    transform: translateX(-500px);
  }to{
    transform: translateX(0);
  }
`

export const slideLeft = css`
  animation: ${slideLeftKeyframes} .2s forwards;
`

export const slideRight = css`
  animation: ${slideRightKeyframes} .2s forwards;
`

export const Container = styled.div`  
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;  
  background-color: ${props => props.theme.colors.white};
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 999;
  padding-top: 100px;

  > form{
    width: 400px;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
  }
  
`
export const BallOverlay = styled.div`
  z-index: -1; 
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100vh;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: ${props => props.theme.colors.silverBG};
`



export const LoginForm = styled.form`
  overflow: hidden;
  max-width: 100%;
  padding: 20px;
`


interface IProps {
  direction?: string;
}


export const AnimationContainer = styled.div<IProps>`
  ${({ direction }) => direction === 'right' ? slideRight :
    direction === 'left' ? slideLeft : ''
  } 
`

export const Title = styled.h1`
  font-size: 2.475rem;
  font-weight: 400;
  text-align: center;

  &:after{
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    margin: 15px 0 0px;
    background: ${props => props.theme.colors.silverBG};
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Link = styled.a`
  color: ${props => props.theme.colors.primary};
  font-weight: 500;
  font-size: .75rem;
  :hover{
    cursor: pointer;
  }
`

export const SubTitle = styled.h2`
  font-size: 1rem;
  font-weight: 100;
`

export const PowerdBy = styled.span`
  font-size: .65rem;
  font-weight: 100;
  margin-top: 10px;
`