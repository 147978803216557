import React, { useContext, useEffect, useState } from 'react';
import { IBodyProgramacaoRonda, IListProgramacaoRonda, IProgramacaoRondasResponse } from '../../../@interfaces';


import BoxContainer from '../../../components/boxcontainer';
import { CustumerContext } from '../../../contexts/custumer';
import APIRondas from '../../../services/APIRondas';
import ToastContext from '../../../contexts/toast';

import {
  Ul,
  ItemDescription,
} from "./styles"
import moment from 'moment';



const ListProgramacoes: React.FC = () => {

  const { id_current_custumer } = useContext(CustumerContext);
  const { showErrorToast } = useContext(ToastContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [programacoes, setProgramacoes] = useState<IListProgramacaoRonda[]>([]);

  useEffect(() => {
    if (id_current_custumer && id_current_custumer > 0) {
      getData();
    }
  }, [id_current_custumer])



  async function getData() {
    try {
      setLoading(true);
      setProgramacoes([]);

      const body: IBodyProgramacaoRonda = {
        cliente: id_current_custumer,
      }

      const response: IProgramacaoRondasResponse = await APIRondas.gerProgramacoesRonda(body);

      setLoading(false);

      if (response.error) {
        showErrorToast(response.message ? response.message : "Erro desconhecido");
        return;
      }
      if (response.listaRonda && response.listaRonda.length > 0) {
        const _programacoes = response.listaRonda.map((i) => {
          return {
            ...i,
            dataHoraInicioRonda: moment(i.dataHoraInicioRonda.replace("Z", "")).format("DD/MM/YY HH:mm"),
            dataHoraFinalRonda: moment(i.dataHoraFinalRonda.replace("Z", "")).format("DD/MM/YY HH:mm"),
          }
        })
        setProgramacoes(_programacoes)
      }

    } catch (error) {
      setLoading(false);
      throw new Error("getProgramacoes: " + error);
    }
  }



  return (
    <BoxContainer title="Próximas progamações de ronda" gridArea='programacoes' loading={loading}>
      <Ul>
        {
          programacoes?.map((item, index) => {
            return (
              <li>
                <span />
                <ItemDescription key={index}>
                  <h2>{item.nomeRonda}</h2>
                  <h3>{`${item.dataHoraInicioRonda} até ${item.dataHoraFinalRonda}`}</h3>
                </ItemDescription>
              </li>
            )
          })
        }
      </Ul>
    </BoxContainer>
  )
}

export default ListProgramacoes;