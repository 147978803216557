import React from "react";

import {
  Route,
  Switch,
  Redirect,
  HashRouter
} from "react-router-dom";


import Home from "../pages/home";
import AccessControl from "../pages/accesscontrol";
import AppLayout from "../components/applayout"
import { MenuContextProvider } from "../contexts/menu";

import Financeiro from "../pages/faturaseboletos";
import Ronda from "../pages/rondas"
import OcorrenciaDetalhe from "../pages/rondas/ocorrenciadetalhe"

import Funcionarios from "../pages/funcionarios"
import PasswordChange from "../pages/login/passwordchange";
import Ponto from "../pages/ponto";
import Documentos from "../pages/documentos";



const Rotas: React.FC = () => {

  return (
    <MenuContextProvider>
      <AppLayout>
        <Switch>
          <Route path={"/"} exact component={Home} />
          <Route path={"/rondas"} exact component={Ronda} />
          <Route path={"/usuarios"} exact component={AccessControl} />
          <Route path={"/financeiro"} exact component={Financeiro} />
          <Route path={"/rondas"} exact component={Ronda} />
          <Route path={"/ponto"} exact component={Ponto} />
          <Route path={"/ocorrenciadetalhe"} exact component={OcorrenciaDetalhe} />
          <Route path={"/trocadesenha/:id"} exact component={PasswordChange} />
          <Route path={"/funcionarios"} exact component={Funcionarios} />
          <Route path={"/documentos"} exact component={Documentos} />
          <Route path={"/ocorrenciadetalhe"} exact component={OcorrenciaDetalhe} />
          <Route path={"/trocadesenha/:id"} exact component={PasswordChange} />

          <Route path={"*"}>
            <Redirect to="/" />
          </Route>

        </Switch>
      </AppLayout>
    </MenuContextProvider>
  )
}

export default Rotas;