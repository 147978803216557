import React, { useContext } from "react";
import { Container, Label } from "./styles";
import { useHistory } from "react-router";
import { IMenuItemProps } from "../../@interfaces";
import MenuContext from "../../contexts/menu";
import { useTheme } from "styled-components";



const MenuItem: React.FC<IMenuItemProps> = ({ label, link, Icon, showLabel, isActive }) => {
  const history = useHistory();
  const { navigateTo, toggleMenu, isOpen } = useContext(MenuContext);
  const theme = useTheme();
  
  function handleClick() {
    history.push(link);
    navigateTo(link);
    if (isOpen)
      toggleMenu();
  }
  
  return (
    <Container isLabelVisible={showLabel} onClick={handleClick} isActive={isActive}>
      <Icon size={16} color={theme.colors.white} />
      {showLabel && <>
        <Label>{label}</Label>
      </>}
    </Container>
  )
}

export default MenuItem;